import "lazysizes"
// import a plugin
import "lazysizes/plugins/parent-fit/ls.parent-fit"
import React, {
  Suspense,
  createContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react"
import pageBuilder from "./Builder/index"
import { graphql, navigate } from "gatsby"
//  import "bootstrap/dist/css/bootstrap.min.css"
import sortBy from "lodash/sortBy"
import SSRProvider from "react-bootstrap/SSRProvider"
import { ProviderAPI } from "./PageStateContext"
import {
  GoogleReCaptchaProvider,
  GoogleReCaptcha,
} from "react-google-recaptcha-v3"
import { setCaptchaResponse } from "../redux/slices/states"
import { useDispatch } from "react-redux"
import { Head } from "./Builder/Head"
import { setNotificationRef } from "./Builder/Components/Notification/Notify"

// const FontSchemeManager = loadable(() => import("./FontScheme/index"))
// const PrimeAgileHelmet = loadable(() => import("./Helmet/index"))

export const PageStateContext = createContext(null)

export default function PrimeAgile({ data, pageContext }) {
  const appendScriptsOnScrollOptions = { once: true }
  const [context, setContext] = useState({ ...pageContext })

  const addCaptha = () => {
    setContext(p => ({
      ...p,
      loadcaptha: true,
    }))
    // pageContext.loadcaptha = true
  }

  useEffect(() => {
    window.addEventListener(
      "mousemove",
      addCaptha,
      appendScriptsOnScrollOptions
    )

    return () => {
      window.removeEventListener(
        "mousemove",
        () => {
          // setLoad(false)
        },
        appendScriptsOnScrollOptions
      )
    }
  }, [])

  let TREE =
    data.primeAgile?.layoutWithObjectTree?.uiObjectWithChildTreeR
      ?.uiObjectChildTree

  let LayoutCSS =
    data.primeAgile?.layoutWithObjectTree?.uiObjectWithChildTreeR?.jsonData?.css
      ?.fileUrl

  TREE = pageBuilder(sortBy(TREE, ["objectOrder"]))
  const dispatch = useDispatch()

  var verifyCallba = function (response) {
    dispatch(setCaptchaResponse(response))
  }

  const PrimeAgileHelmet = React.lazy(() =>
    import("./Helmet/index").then(module => {
      return { default: module.default }
    })
  )

  const FontSchemeManager = React.lazy(() =>
    import("./FontScheme/index").then(module => {
      return { default: module.default }
    })
  )

  const ErrorBoundary = React.lazy(() =>
    import("./ErrorBoundary/index").then(module => {
      return { default: module.default }
    })
  )

  const NotificationManager = React.lazy(() =>
    import("./Builder/Components/Notification/NotificationManager").then(
      module => {
        return { default: module.default }
      }
    )
  )

  // const ErrorBoundary = loadable(() => import("./ErrorBoundary/index"))

  // useEffect(() => {
  //   if (window.localStorage) {
  //     if (!localStorage.getItem("firstLoad")) {
  //       localStorage["firstLoad"] = true
  //       window.location.reload()
  //     } else localStorage.removeItem("firstLoad")
  //   }
  // }, []) // The effect will run whenever hasReloaded changes

  const notificationRef = useRef()

  useEffect(() => {
    setNotificationRef(notificationRef)
  }, [notificationRef])


  return (
    <>
      <SSRProvider>
        <Suspense fallback={<div />}>
          <NotificationManager ref={notificationRef} />
          <ErrorBoundary>
            <ProviderAPI value={context} id="primeagile__entry">
              <FontSchemeManager fontScheme={context.fontScheme}>
                <Head
                  pageContext={context}
                  LayoutCSS={LayoutCSS}
                  pageCss={context?.pageCss?.fileUrl}
                />

                <PrimeAgileHelmet pageContext={context} LayoutCSS={LayoutCSS} />

                {context?.loadcaptha && context.captchaSiteKey ? (
                  <GoogleReCaptchaProvider
                    reCaptchaKey={context.captchaSiteKey}
                    scriptProps={{
                      async: false, // optional, default to false,
                      defer: true, // optional, default to false
                      appendTo: "head", // optional, default to "head", can be "head" or "body",
                      nonce: undefined, // optional, default undefined
                    }}
                  >
                    <GoogleReCaptcha onVerify={verifyCallba} />

                    {TREE}
                  </GoogleReCaptchaProvider>
                ) : (
                  TREE
                )}
              </FontSchemeManager>
            </ProviderAPI>
          </ErrorBoundary>
        </Suspense>
      </SSRProvider>
    </>
  )
}

export const query = graphql`
  query layoutTree(
    $site: String
    $objectDataDefinitionId: String
    $locale: String
  ) {
    primeAgile {
      layoutWithObjectTree(
        objectDataDefinitionId: $objectDataDefinitionId
        layoutSetId: $site
        locale: $locale
      ) {
        css {
          fileId
          fileName
          fileType
          fileUrl
        }
        uiObjectWithChildTreeR {
          uiObjectChildTree
          jsonData
          type
          objectName
        }
      }
    }
  }
`
